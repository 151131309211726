.login__back{
   overflow: hidden;
   width: 100%;
   height: 100vh;
   background-color: black !important;
}

.admin-login__title{
   color: white;
}
.background{
   max-width: 430px;
   height: 100vh;
   position: absolute;
   transform: translate(-50%,-50%);
   left: 50%;
   top: 50%;
}
.background .shape{
   height: 200px;
   width: 200px;
   position: absolute;
   border-radius: 50%;
}

.shape:first-child{
   background: linear-gradient(
   #1845ad,
   #23a2f6
   );
   left: -80px;
   top: -80px;
}
.shape:last-child{
   background: linear-gradient(
   to right,
   #ff512f,
   #f09819
   );
   left: 0;
   bottom: 0;
}
.admin-Login__form{
   height: 520px;
   width: 400px;
   background-color: rgba(255,255,255,0.13);
   position: absolute;
   transform: translate(-50%,-50%);
   top: 50%;
   left: 50%;
   border-radius: 10px;
   backdrop-filter: blur(10px);
   border: 2px solid rgba(255,255,255,0.1);
   box-shadow: 0 0 40px rgba(8,7,16,0.6);
   padding: 50px 35px;
}
.admin-Login__form *{
   font-family: 'Poppins',sans-serif;
   color: #ffffff;
   letter-spacing: 0.5px;
   outline: none;
   border: none;
}
.admin-Login__form h3{
   font-size: 32px;
   font-weight: 500;
   line-height: 42px;
   text-align: center;
}

.admin-Login__label{
   display: block;
   margin-top: 30px;
   font-size: 16px;
   font-weight: 500;
}
.admin-Login__input{
   display: block;
   height: 50px;
   width: 100%;
   background-color: rgba(255,255,255,0.07);
   border-radius: 3px;
   padding: 0 10px;
   margin-top: 8px;
   font-size: 14px;
   font-weight: 300;
}
::placeholder{
   color: #e5e5e5;
}

#password{
   margin-bottom: 30px;
}
.admin-Login__button{
   margin-top: 50px;
   width: 100%;
   background-color: #ffffff;
   color: #080710;
   padding: 15px 0;
   font-size: 18px;
   font-weight: 600;
   border-radius: 5px;
   cursor: pointer;
}

@media screen and (max-width:400px) {
   .admin-Login__form{
      width: 100%;
   }

   .shape:last-child{
      left: -100px;
   }
}

