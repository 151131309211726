.hero-box{
   position: relative;
   z-index: 1;
   overflow: hidden;
}






.hero-wrapper{
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.hero-title{
   margin: 0;
   font-size: 60px;
   color: white;
   font-weight: 400;
   margin-bottom: 20px;
}

.hero-desc{
   margin: 0;
   font-size: 24px;
   font-weight: 300;
   color: white;
}

@media only screen and (max-width: 2600px) and (min-width: 1600px)  {
   .hero-title{
      font-size: 80px;
   }

   .hero-desc{
      font-size: 35px;
   }
}


@media screen and (max-width:1160px) {
   
   .hero-title{
      padding-top: 0;
      font-size: 50px;
   }
   
   .hero-desc{
      font-size: 20px;
   }
}

@media screen and (max-width:993px) {
   .hero{
      padding-top: 90px;
   }
   .hero-title{
      font-size: 50px;
   }
   
   .hero-desc{
      font-size: 20px;
   }
}

@media screen and (max-width:817px) {


   
   .hero-title{
      font-size: 40px;
   }
   
   .hero-desc{
      font-size: 17px;
   }
}


@media screen and (max-width:480px) {
   
   .hero-title{
      font-size: 45px;
   }
   
   .hero-desc{
      font-size: 20px;
   }
}

@media screen and (max-width:330px) {
   .hero-title{
      font-size: 40px;
   }
   
}