.contact{
   padding-top: 100px;
   padding-bottom: 200px;
   position: relative;
   z-index: 10;
}

.contact-title{
   font-size: 40px;
   font-weight: 500;
   color: #225479;
   margin-bottom: 15px;
}

.contact-wrapper{
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
}

.contact-box__infos{
   width: 370px;
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.contact-infos__box{
   display: flex;
   flex-direction: column;
}

.contact-info{
   font-size: 20px;
   font-weight: 500;
   color: #225479;
}

.contact-infos__link{
   font-size: 18px;
   font-weight: 300;
   color: black;
   text-decoration: none;
}

@media only screen and (max-width: 2600px) and (min-width: 1600px)  {
   .contact-title{
      font-size: 60px;
      margin-bottom: 25px;
   }
   .contact-box__infos{
      width: 470px;
      display: flex;
      flex-direction: column;
      gap: 20px;
   }

   .contact-info{
      font-size: 40px;
   }

   .contact-infos__link{
      font-size: 25px;
   }
}

.ymaps-2-1-79-copyright__text{
   display: none !important;
}

.ymaps-2-1-79-copyright__link{
   display: none !important;
}

@media screen and (max-width:768px) {
   .contact{
      padding-bottom: 100px;
   }
   
   .contact-box__infos{
      margin-top: 100px;
   }
   
   
}

@media screen and (max-width:576px) {
   .contact-title{
      text-align: center;
   }
   
   
}