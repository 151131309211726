.myModal{
   background-color: rgba(0, 0, 0, .5);
   bottom: 0;
   display: none;
   left: 0;
   position: fixed;
   right: 0;
   top: 0;
}

.myModal.active{
   align-items: center;
   display: flex;
   justify-content: center;
   z-index: 10;
}

.myModalContent{
   background-color: #225479;
    border-radius: 30px;
    min-width: 19rem;
    padding: 30px;
}

