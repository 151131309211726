.site-footer{
   padding: 32px 0;
   background-color: #225479;
   position: relative;
   z-index: 10;
}

.footer__wrapper{
   display: flex;
   align-items: center;
   justify-content: space-between;
   flex-wrap: wrap;
   padding-bottom: 30px;
   border-bottom: 1px solid white;
}

.footer__left-box{
   width: 380px;
}

.footer__left-desc{
   font-size: 18px;
   font-weight: 400;
   color: white;  
   margin-top: 10px; 
}

.footer__right-box{
   display: flex;
   gap: 20px;
}

.footer__icon-link{
   display: inline-block;
   width: 50px;
   height: 50px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
   background-color: white;
}

.footer__icon{
   font-size: 30px;
}

.footer__bottom{
   font-weight: 300;
   padding: 15px 0;
   color: white;
   margin-bottom: 0;
}

@media only screen and (max-width: 2600px) and (min-width: 1600px)  {
   .footer__logo-img{
      width: 280px;
      height: 120px;
   }

   .footer__left-box{
      width: 600px;
   }

   .footer__left-desc{
      font-size: 25px;
   }

   .footer__right-box{
      gap: 30px;
   }

   .footer__icon-link{
      width: 70px;
      height: 70px;
   }

   .footer__icon{
      font-size: 40px;
   }
}