

.ymaps-2-1-79-map{
  max-width: 400px !important;
  height: 300px !important;
  width: 100% !important;
}
.map-h2 {
   text-transform: uppercase;
   font-size: 1rem;
   padding: 20px;
   padding-left: 10px;
   text-align: center;
 }
 
 .google-map {
   width: 100%;
   height: 60vh;
 }
 
 .pin {
   display: flex;
   align-items: center;
   width: 180px;
   color: var(--main-blue);
 }
 
 .pin:hover {
   cursor: pointer;
 }
 
 .pin-icon {
   font-size: 4rem !important;
 }
 
 .pin-text {
   background: rgba(255, 255, 255, 0.9);
   border-radius: 10px;
   padding: 10px;
   font-size: 1.3em;
   color: var(--main-blue);
 }

 @media screen and (max-width:340px) {
  .ymaps-2-1-79-map{
    max-width: 300px !important;
    height: 300px !important;
    width: 100% !important;
  }

  .map-box div{
    width: 280px !important;
  }
}
 
 @media screen and (min-width: 799px) {
   .google-map {
     height: 80vh;
   }
 
   .map-h2 {
     font-size: 1.3rem;
     font-weight: 400;
   }
 
   .pin {
     width: 15vw;
   }
 
   .pin-icon {
     font-size: 10vw;
   }
 }
 