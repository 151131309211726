
@font-face {
  font-display: swap; 
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/roboto-v30-cyrillic-ext_latin-300.woff2') format('woff2');
}

@font-face {
  font-display: swap; 
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/roboto-v30-cyrillic-ext_latin-regular.woff2') format('woff2');
}

@font-face {
  font-display: swap; 
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/roboto-v30-cyrillic-ext_latin-500.woff2') format('woff2');
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  scroll-behavior: smooth;
  font-family: "Roboto","Arial",sans-serif !important;
  font-weight: 500 !important;
}

a{
  text-decoration: none;
}

@media only screen and (max-width: 2600px) and (min-width: 1280px)  {
  .container{
    max-width: 2000px !important;
    margin: 0 auto !important;
    padding: 0 50px !important;
  }
}