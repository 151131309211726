.site-header {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 15;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.nav-list {
  display: flex;
  list-style: none;
  align-items: center;
  margin: 0;
  padding: 0;
}

.nav-link {
  color: black !important;
  font-size: 22px !important;
}

.nav-link:hover {
  color: #225479 !important;
}

.header-btn {
  padding: 8px 13px;
  color: white;
  background-color: #225479;
  border-radius: 10px;
}

.dropdown{
  width: 69px;
  height: 48px; 
  font-size: 12px;  
  margin-right: 18px;
  color: #2189ff;
  border: 1px solid #eaf4ff;
  border-radius: 16px;
  margin-left: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
  
  .header-burger {
    font-size: 40px;
    padding: 1px 5px;
    border: 1px solid #225479;
    border-radius: 3px;
    display: none;
  }
  
  .header-burger:hover {
    background-color: #225479;
    color: white;
  }
  
  .left-box {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(158, 144, 144, 0.336);
    transition-duration: 0.5s;
  }
  
  .burger-nav {
    top: 0;
    width: 80%;
    height: 100vh;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-color: white;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 20px 20px 20px 30px;
  }
  
  .not-hide-burger-nav {
    left: 0px;
  }
  
  .hide-burger-nav {
    left: -100%;
  }
  
  .close {
    font-size: 30px;
  }
  
  .burger-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px 0 0 0;
  }
  
  .burger-link {
    color: black;
    font-size: 20px !important;
    text-decoration: none;
  }
  
  .header-burger-btn {
    padding: 5px 10px;
    color: white;
    background-color: #225479;
    border-radius: 10px;
  }

  @media only screen and (max-width: 2600px) and (min-width: 1600px)  {
    .logo-img{
      width: 310px;
      height: 110px;
    }

    .nav-link{
      font-size: 30px !important;
    }

    .header-btn{
      font-size: 25px;
      padding: 12px 17px;
    }
    .dropdown{
      width: 89px;
      height: 55px;
      font-size: 25px !important;
    }

    .ant-dropdown-menu-title-content{
      font-size: 20px !important;
    }
  }
  
  @media screen and (min-width: 992px) {
    .not-hide-burger-nav {
      left: -100%;
    }
  }
  
  @media screen and (max-width: 992px) {
    .nav-list {
      display: none;
    }
    
    .header-btn {
      display: none;
    }

    .dropdown{
      display: none;
    }

    .burger-dropdown{
      width: 80px;
      height: 40px;
      display: block;
      margin-top: 10px;
      margin-left: 0;
    }
    
    .header-burger {
      display: block;
    }
    
    .hide-burger-nav {
      left: -100%;
    }
  }

  @media screen and (max-width:742px) {
    .logo-img{
      width: 150px;
      height: 80px;
    }
   
 }


  